<template>
<loader v-bind="{ loading }">
  <columns>
    <column class="has-text-centered">
      <h1 class="title">Plans</h1>
      <switch-input 
        classes="is-rounded is-success" 
        v-model="annual"
        :description="annual ? 'You\'re saving 10%' : 'Save 10% by switching to annual billing'">
        Annual
      </switch-input>
    </column>
  </columns>
  <columns class="is-multiline">
    <column v-for="plan in plans" :key="plan.id" class="is-4">
      <plan-preview 
        v-bind="{ annual, plan, card }"
      />
    </column>
  </columns>
</loader>
</template>
<script>
import { mapGetters } from 'vuex'
import PlanPreview from './partials/PlanSelector.vue'
import { billing as backend } from '@/api'

export default {

  components: {
    PlanPreview
  },

  data: () => ({
    annual: false,
    loading: true,
    card: null
  }),

  async created() {
    await this.$store.dispatch('billing/loadPlans').catch(() => {})
    await backend.loadCard(({data}) => {
      if(data) {
        this.card = data
      }
    }, () => {})
    this.loading = false
  },

  computed: {
    ...mapGetters('billing', [
      'plans',
      'subscription'
    ])
  }

}
</script>