<template>
<div class="box is-fullheight">
  <columns>
    <column>
      <h3 class="title is-4 mb-1">
        {{ plan.title }} <span v-if="isCurrentPlan" class="tag is-small is-warning is-rounded">Current Plan</span>
      </h3>      

      <columns>
        <column>
          <div class="content" v-html="plan.description"></div>
        </column>
      </columns>
      <columns>
        <column>
          <p v-if="plan.title !== 'Free'">
            {{ activePrice | asCurrency(price.currency_symbol) }} <span v-if="activePrice > 0">{{ plan.price_denotation }} billed {{ annual ? 'annually' : 'monthly' }}</span>
          </p>
          <p v-else>
            {{ price.currency_symbol }} 0.00
          </p>
          <p>
            <span class="has-text-success" v-if="annual && plan.title !== 'Free'">Saving 10%</span>
            <span v-else>&nbsp;</span>
          </p>
        </column>
      </columns>
      
      <columns>
        <column>
          <action-button
            :working="activating"
            @click="selectPlan"
            v-if="!isCurrentPlan && plan.title !== 'Free'" 
            class="is-rounded">
            <span v-if="!canStartTrial">
              <span v-if="isADowngradeIfSelected">Downgrade</span>
              <span v-else>Select Plan</span>
            </span>
            <span v-else>Start Trial</span>
          </action-button>
          <action-button
            :working="activating"
            @click="downgradeToFree"
            v-if="!isCurrentPlan && plan.title === 'Free'" 
            class="is-rounded">
            Downgrade
          </action-button>
          <span v-if="isCurrentPlan && isOntrial" class="tag is-medium is-rounded is-warning">
            On Trial
          </span>
        </column>
      </columns>
      
    </column>
  </columns>
</div>
</template>
<script>
import { addCard } from '@/modals'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {

  props: {
    annual: Boolean,
    plan: {
      type: Object,
      default: () => ({
        prices: []
      })
    },
    card: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    activating: false
  }),

  methods: {
    selectPlan() {

      if(this.isADowngradeIfSelected && this.usage.users > this.plan.limits.users) {
        return this.$alert({
          title: 'Too many active users',
          message: 'Please deactivate the users you no longer require before downgrading.'
        })
      }

      if(this.isADowngradeIfSelected) {
        this.$store.dispatch('billing/downgradeToLowerTier', this.price.id).then(() => {
          this.$store.dispatch('billing/loadSubscription')
          this.$router.push({
            name: 'dashboard'
          })
          this.$toast.success('Subscription Successfully Downgraded')
        })
        return
      }

      this.$store.commit('billing/setSubscriptionCheckout', {
        plan_price_id: this.price.id,
        annual: this.annual
      })
      if(this.canStartTrial) {
        this.activating = true
        this.$store.dispatch('billing/startTrial', this.subscription_checkout).then(() => {
          this.activating = false
          this.$store.dispatch('billing/loadSubscription')
          this.$toast.success('Your Subscription has been activated.')
          this.$router.push({
            name: 'billing'
          })
        }).catch(() => this.activating = false)
      } else {
        this.activateAndBillForSubscription()
      }
    },

    activateAndBillForSubscription() {
      if(!this.card) {
        this.activating = true
        this.addCard().then(() => this.activating = false) 
      } else {
        this.activating = true
        this.$store.dispatch('billing/upgradePlan', this.price.id).then(() => {
          this.activating = false
          this.$store.dispatch('billing/loadSubscription')
          this.$toast.success('Your Subscription has been activated.')
          this.$router.push({
            name: 'billing'
          })
        }).catch(() => this.activating = false)
      }
    },

    async addCard() {
      this.generatingCheckout = true
      await this.$store.dispatch('billing/generateNewCardCheckout', {
        return_url: '/complete-subscription',
        plan_price_id: this.price.id,
        annual: this.annual
      }).then(async data => {
        await addCard(data)
        this.generatingCheckout = false
      }, () => {
        this.generatingCheckout = false
      })
    },

    async downgradeToFree() {
      if(await this.$confirm({
        title: 'Downgrade to Free Account',
        message: 'When downgrading to a free account all users except for the primary account user will be deactivated and Free Tier limits will be enforced.',
        confirmClass: 'is-danger',
        confirmText: 'Downgrade My Account'
      })) {
        this.$store.dispatch('billing/downgradeToFree').then(() => {
          this.$store.dispatch('billing/loadSubscription')
          this.$router.push({
            name: 'billing'
          })
          this.$toast.success('Account Successfully Downgraded')
        }).catch(() => {
          this.$toast.error('An error occurred while trying to downgrade your account. Please contact support.')
        })
      }
    }
  },

  computed: {
    ...mapGetters('billing', [
      'subscription',
      'subscription_checkout',
      'previous_trials',
      'usage'
    ]),
    canStartTrial() {
      return !this.subscription.trial_expires_at && this.previous_trials === 0
    },
    price() {
      return this.plan.prices[0]
    },
    activePrice() {
      return this.annual ? this.price.annual_price : this.price.monthly_price
    },
    isCurrentPlan() {
      return this.subscription.price.plan_id === this.plan.id
    },
    isOntrial() {
      return this.subscription.trial_expires_at && moment(this.subscription.trial_expires_at).isAfter(moment())
    },
    currentlyActivePlan() {
      return this.subscription.price.plan
    },
    isADowngradeIfSelected() {
      return this.plan.sort < this.currentlyActivePlan.sort
    }
  }

}
</script>